:root {
  --black: #FDF8EF;
  /* --main: #42523D;
  --secondary: #7D967A; */
  --hover: #CAB4A5;
  /* --white: #FDF8EF; */
}
.App {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  color: var(--black);
  min-height: 100vh;
  background-color: #1F0001;
  position: relative;
  /* padding-bottom: 100px; */
  /* border: 1px solid red; */
}
.App-header {
  background-color: #1F0001;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--black);
}
.App-link {
  color: #61dafb;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1 {
  color: var(--black);
  text-align: right;
  font-weight: 500;
  margin: 10px;
}
h2{
  text-align: left;
  font-weight: 500;
  font-size: 40px;
  padding-right: 15px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--black);
  color: transparent;
}
h4{
  font-family: neue-haas-grotesk, sans-serif;
  color: var(--black);
  font-weight: 200;
  text-align: left;
  font-size: 10px;
}
h5{
  text-align: left;
  color: var(--black);
  font-weight: 300;
  font-size: 16px;
}
h6{
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  margin: 10px;
  padding: 10px;
  color: var(--black);
}
p{
  color: var(--black);
  font-weight: 400;
  text-align: right;
  margin: 10px;
  font-size: 16px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.navbar {
  height: 80px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  /* border: 1px solid green; */
}
.leftnav{
  align-items:left;
  display: flex;
  justify-content: left;
  /* border: 1px solid red; */
}
.rightnav{
  align-items:right;
  display: flex;
  justify-content: right;
  /* border: 1px solid blue; */
}
.home, .about, .contact, .resume {
  display: inline;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3rem;
  /* border: 1px solid yellow; */
}

.image{
  display: inline;
  pointer-events: auto;
  cursor: pointer;
  font-style: italic;
}
.hover-image{
  display: none;
}
body .image:hover + .hover-image{
  display: flex;
  pointer-events:none;
}
.hover-image{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  pointer-events: none;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  height: 70vh;
}
.hover-image img{
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto !important;
  margin-bottom: 0;
}
.home{
  display: inline-block;
  width: 100%;
height: 80vh;
}
.home-top h1{
  text-align: right;
  right: 0 !important;
  padding-top: 15%;
  font-size: 3em;
  vertical-align: middle;
  align-items: center;
  line-height: 0.8;
}
.project-text h1{
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 7em;
  vertical-align: middle;
  align-items: center;
}
.project-page{
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 60px)) 1fr;
  grid-gap: 40px;
  padding: 60px 0;
}
.project-page h4{
  font-family: neue-haas-grotesk, sans-serif;
  color: var(--black);
  font-weight: 200;
  text-align: left;
  font-size: 16px;
  line-height: 1.3 !important;
}
.project-page h5{
  text-align: left;
  color: var(--black);
  font-weight: 300;
  font-size: 14px;
}
.project-page h5 a{
  text-decoration: none;
  color: var(--black);
}
.project-page h5 a:hover{
  color: var(--hover);
}
.projects{
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 60px));
  grid-gap: 40px;
}
.individual{
  grid-column-end: span 6;
  display: flex;
  flex-direction: column;
}
.individual-image-container{
  width: 100%;
  overflow: hidden;
  position: relative;
}
.individual-image-container img{
  width: 100%;
  height: 320px;
  object-fit: cover; 
  position: relative;
}
.individual-image-container video{
  width: 100%;
  height: 320px;
  object-fit: cover; 
  position: relative;
}
.individual-content{
  margin-top: 10px;
}

span {
  margin-left: 16px;
}
@media screen and (max-width: 600px){
  .navbar {
    height: auto;
    width: 100%;
    /* border: 1px solid green; */
  }
  .projectnav h6, .contactnav h6, .aboutnav h6, .homenav h6, .resumenav{
    font-size: small;
    text-decoration: none;
  }
}
a.projectnav, a.contactnav, a.aboutnav, a.homenav{
  text-decoration: none;
}
a.projectnav h6:hover, a.contactnav h6:hover, a.aboutnav h6:hover, a.homenav h6:hover, .resumenav:hover{
  color:var(--hover)
}

.footer {
  display: flex;
  bottom: 0;
  justify-content:space-between;
  padding-top: 5px;
  align-items: center;
  /* height: 80px; */
  width: 100%;
  /* border: 1px solid green; */
}
.footer a{
  text-decoration: none !important;
}
.footer a:hover h6{
  color: var(--hover) !important;
}
.footer-left{
  display: inline-block;
  align-items: left;
  padding-bottom: 3px;
}
.footer-left p, a{ 
  text-align: left; 
  text-decoration: none;
}
.footer-right{
  /* border: red solid 1px; */
  display: flex;
  justify-content:right;
  vertical-align: bottom !important;
  align-items: right;
  padding-bottom: 3px;
}
.footer-right h6{
  padding-right: 10px;
  text-align:right;
}

@media screen and (max-width: 720px){
  .App{
    overflow: hidden;
  }
  .project-page{
    grid-gap: 20px;
  }
  .sample-image{
    background-image: url('./assets/sample_image.png');
    background-size: 100%;
    background-repeat: no-repeat;
    height: 250px !important;
  }
  .projects{
    grid-column: 2 / span 12;
  }
  .individual{
    grid-column-end: span 10;
  }
  .home-top h1{
    padding-top: 40%;
    font-size: 1.25em;
  }
  .project-text h1{
    font-size: 4.5em;
  }
  .footer h6{
    font-size: 0.5rem;
  }
}
